'use client';

import { Dispatch, SetStateAction, createContext, useContext, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { ReactLenis, useLenis } from '@studio-freight/react-lenis';

import { MenuProps } from './wordpress/_types/blocksStyle';
import React from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import { getLanguagePreferencesInStorage } from './helpers/language.utils';
import { useRouter, usePathname } from 'next/navigation';
import getMenus from './actions/menusAction';
import { Loader } from 'daskapital-ds';
import Loading from '@/app/loading';

interface ContextProps {
	i18n?: { [x: string]: Record<string, string> | string } | any;
	lang?: string;
	translatedUri?: { [x: string]: Record<string, string> } | null;
	setTranslatedUri?: Dispatch<SetStateAction<{ [x: string]: Record<string, string> | undefined | null } | null>> | any;
	setMenus?: Dispatch<SetStateAction<any>>;
	setLang?: Dispatch<SetStateAction<string | undefined>>;
	menus?: {
		[x: string]: MenuProps[];
	};
	themeOptions?: {
		socials?: Record<string, string>[];
	};
	dict?: Record<string, Record<string, string> | any>;
	lenis?: Record<string, Record<string, string> | any>;
	handleMenusPerLanguage?: (locale: string) => void;
	footer?: any;
}

const GlobalContext = createContext<ContextProps>({
	i18n: {},
	lang: '',
	translatedUri: {},
	setTranslatedUri: undefined,
	themeOptions: {},
	dict: {},
	lenis: {},
	footer: {},
	setLang: function (value: SetStateAction<string | undefined>): void {
		throw new Error('Function not implemented.');
	},
	setMenus: function (value: SetStateAction<string | undefined>): void {
		throw new Error('Function not implemented.');
	},
	handleMenusPerLanguage: function (): void {
		throw new Error('Function not implemented.');
	},
});

export const GlobalContextProvider = ({ children, value }: { children: React.ReactNode; value: ContextProps }) => {
	gsap.registerPlugin(ScrollTrigger);

	ScrollTrigger.config({ ignoreMobileResize: true });
	const [isLoadingEnviroment, setIsLoadingEnviroment] = useState<boolean>(false);
	const [translatedUri, setTranslatedUri] = useState();
	const [lang, setLang] = useState(value.lang);
	const i18n = value.i18n || {};
	const [menus, setMenus] = useState();
	const [dict, setDict] = useState();

	const [footer, setFooter] = useState();
	const themeOptions = value.themeOptions || {};

	const lenis = useLenis(() => {
		// called every scroll
	});

	useEffect(() => {
		handleMenusPerLanguage(lang || 'en');
	}, [lang]);

	const [isPending, startTransition] = useTransition();
	const router = useRouter();

	const handleMenusPerLanguage = async (locale: string) => {
		setIsLoadingEnviroment(true);
		startTransition(async () => {
			translatedUri && router.replace(translatedUri[locale]);
			const newMenus = await getMenus(locale);
			try {
				setMenus(newMenus.menus);
				setFooter(newMenus.footer);
				setDict(newMenus.dict);
			} catch (error) {
				console.error('Error fetching menus:', error);
			} finally {
				setIsLoadingEnviroment(false);
			}
		});
	};

	const GlobalContextValues = useMemo(() => {
		return {
			i18n,
			lang,
			translatedUri,
			menus,
			themeOptions,
			dict,
			lenis,
			footer,
			setTranslatedUri,
			setLang,
			setMenus,
			handleMenusPerLanguage,
		};
	}, [i18n, lang, translatedUri, menus, themeOptions, dict, lenis, footer, setTranslatedUri, setLang, setMenus, handleMenusPerLanguage]);

	if (isPending) return <Loading></Loading>;

	return (
		<GlobalContext.Provider value={GlobalContextValues}>
			<ReactLenis
				root
				options={{
					duration: 1.2,
					easing: (t: number) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
				}}>
				{children}
			</ReactLenis>
		</GlobalContext.Provider>
	);
};

export const useGlobalContext = () => useContext(GlobalContext);
