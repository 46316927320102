'use client';

import { Button, Icon } from 'daskapital-ds';

import Link from 'next/link';
import classNames from 'classnames';
import { scrollTo } from '@/lib/helpers/scrollHelpers';
import { useGlobalContext } from '@/lib/globalContext';
import { useMemo } from 'react';

export type IBlockButton = {
	attrs: {
		id?: string;
		variant?: string;
		text?: string;
		href?: {
			url?: string;
			newTab?: boolean;
		};
		icon?: string;
		iconLeft?: boolean;
		mode?: string;
		className?: string;
		style?: Record<string, unknown>;
		animation?: string;
	};
};

export default function BlockButton({ attrs }: IBlockButton) {
	const state = useGlobalContext();
	const { id, variant, text, href, icon, iconLeft, mode, className, style, animation } = attrs || {};

	const styles = useMemo(
		() => ({
			...style,
		}),
		[style]
	);

	// No button href? Bail.
	const url = href?.url?.includes('wp-content') ? `${process.env.NEXT_PUBLIC_WP_URL}${href.url}` : href?.url;
	const newTab = href?.newTab;

	const linkTarget = newTab ? '_blank' : '_parent';
	// if (!url) return <></>

	const anchorScroll = (e: any, url: string) => {
		if (url.indexOf('#') === 0) {
			e.preventDefault();
			scrollTo(state, url, true);
		}
	};

	if (linkTarget === '_blank' || !url) {
		return (
			<Button
				id={id}
				variant={variant}
				mode={mode}
				href={url}
				target={linkTarget}
				rel={linkTarget ? 'noreferrer noopener' : ''}
				aria-label={text}
				className={classNames(className, animation && `elements_animated ${animation}`)}
				style={styles}>
				{text}
			</Button>
		);
	}

	return (
		<Link href={url} legacyBehavior={true}>
			<Button
				id={id}
				href={url}
				variant={variant}
				mode={mode}
				leftIcon={iconLeft && icon ? <Icon icon={icon} /> : <></>}
				rightIcon={!iconLeft && icon ? <Icon icon={icon} /> : <></>}
				className={classNames(className, animation && `elements_animated ${animation}`)}
				style={styles}
				onClick={(e: any) => {
					anchorScroll(e, url);
				}}>
				{text}
			</Button>
		</Link>
	);
}
