"use client";

import { Flex, LogosCard, Slider } from "daskapital-ds";
import { IArchiveGrid, IPost } from "@/lib/wordpress/_types/archiveGrid";

import Image from "next/image";
import React from "react";

export default function BlockLogosGrid({ attrs }: IArchiveGrid) {
  const { grid, className } = attrs || {};

  return (
    <>
      {grid?.items && (
        <Slider
          slidesToShow={grid?.items.length}
          infinite={true}
          arrows={false}
          autoplay={true}
          mobileOnly={false}
          mediaQueries={{
            1150: {
              slidesToShow: 5
            },
            900: {
              slidesToShow: 4,
              autoplay: true
            },
            780: {
              slidesToShow: 3
            },
            560: {
              slidesToShow: 2
            }
          }}
          className={className || ""}
        >
          {grid?.items.map((post: IPost, i: number) => {
            return (
              <LogosCard
                key={`logo-${i}`}
                title={post?.title}
                imageElement={
                  post?.image !== "" ? (
                    <Image
                      src={post?.image as string}
                      alt={post?.title as string}
                      width={195}
                      height={40}
                      style={{ objectFit: "contain" }}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            );
          })}
        </Slider>
      )}
    </>
  );
}
